import React from 'react';
import Header from '../components/Header';

function AMVs() {
  return (
    <div>
      <Header />
      <p>TODO</p>
    </div>
  );
}

export default AMVs;
